<template>
  <div class="container mt-3">
    <VueLoading v-model:active="state.isLoading" color="#196bb0"></VueLoading>
    <div class="row">
      <VeeForm @submit="useUpdateUserInfo">
        <div class="mb-3 col-md-4 offset-md-4">
          <label for="userName" class="form-label fs-5">使用者名稱</label>
          <div>
            <Field
              type="text"
              class="form-control"
              id="userName"
              name="userName"
              v-model="user.name"
            />
          </div>
          <ErrorMessage name="userName" v-slot="{ message }">
            <div class="d-flex align-items-center mt-1">
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="ms-1 me-1" />
              <p class="m-0">{{ message }}</p>
            </div>
          </ErrorMessage>
        </div>
        <div class="mt-3 text-center col-md-4 offset-md-4">
          <button type="submit" class="btn btn-primary me-3">儲存</button>
        </div>
      </VeeForm>
    </div>
  </div>
</template>

<script>
// 載入 Vue 3 composition api
import { ref, toRefs } from 'vue';
// 載入 loading overlay
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// 載入 lodash
import _ from 'lodash';
// 載入 vee-validate
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
// 載入 sweetalert2
import useVueSweetAlert2 from '@/composables/useVueSweetAlert2';
import useUserInfo from '@/composables/useUserInfo';

export default {
  components: {
    VueLoading,
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    userUid: String,
    userEmail: String,
  },
  setup(props) {
    const $swal = useVueSweetAlert2();
    // ================================
    const { userUid } = toRefs(props);
    const { state, getUserInfo, updateUserInfo } = useUserInfo();
    const user = ref({});

    function useUpdateUserInfo() {
      updateUserInfo(userUid.value, user.value, () => {
        if (state.isError) {
          $swal.fire({
            text: String(state.errorMessage),
            icon: 'error',
          });
        } else {
          $swal.fire({
            text: '儲存成功',
            icon: 'success',
          });
        }
      });
    }
    // 表單檢查規則
    function isRequired(value) {
      return value ? true : '此為必填項目';
    }

    // onCreate
    getUserInfo(userUid.value, (userData) => {
      if (state.isError) {
        $swal.fire({
          text: String(state.errorMessage),
          icon: 'error',
        });
      } else {
        user.value = _.cloneDeep(userData);
      }
    });

    return {
      state,
      user,
      isRequired,
      useUpdateUserInfo,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  color: $primary;
}
</style>
