import { reactive } from 'vue';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '@/assets/firebase';

export default function useUserInfo() {
  const state = reactive({
    isError: false,
    isLoading: false,
    errorMessage: '',
  });
  async function getUserInfo(id, cb) {
    state.isLoading = true;
    try {
      const docRef = doc(db, 'users', id);
      const docSnap = await getDoc(docRef);
      const userData = docSnap.data();
      userData.uid = id;
      state.isLoading = false;
      if (cb) {
        cb(userData);
      }
    } catch (e) {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = e.message;
      if (cb) {
        cb();
      }
    }
  }
  async function updateUserInfo(id, userData, cb) {
    state.isLoading = true;
    try {
      const docRef = doc(db, 'users', id);
      await updateDoc(docRef, userData);
      state.isLoading = false;
      if (cb) {
        cb();
      }
    } catch (e) {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = e.message;
      if (cb) {
        cb();
      }
    }
  }
  return {
    state,
    getUserInfo,
    updateUserInfo,
  };
}
